<template>
  <!-- footer START -->
  <transition
    name="fade-slow"
    mode="out-in"
  >
    <div
      :class="[
        'footer',
      ]"
    >
      FOOTER
    </div>
  </transition>
  <!-- footer END -->
</template>

<script>export default {
  name: 'app-footer',
  data() {
    return {
    };
  },
};
</script>
